import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {
    CompetitionTestRef,
    CompetitionTestSetRef,
    SportCategory
} from "@/modules/competition/service/CompetitionTestsService";
import {FileContent, httpService} from "@/services/HttpService";
import {SportTypeVariant} from "@/modules/federation/services/SportTypeVariantService";
import {
    CrossCountryObstacle,
    DressageJuryPosition,
    JumpingObstacle
} from "@/modules/competition/service/ProtocolSettingsService";
import {CompetitionParticipation, CompetitionPractitioner, PersonName} from "@/shared/domain/CompetitionPractitioner";
import {RefWithName} from "@/utils/Utils";
import {
    CrossCountryObstacleErrorType,
    SportResultDataType,
    SportResultInput,
    SportResultStatus,
    TestResult
} from "@/components/competition/SportResult";

class ManageResultsService {
    getData(ref: CompetitionRef): Promise<CompetitionResultsData> {
        return httpService.get(`/competition/manage/${ref}/results/metadata`)
    }

    getResults(ref: CompetitionRef, test: CompetitionTestRef, testSet: CompetitionTestSetRef): Promise<Results> {
        return httpService.get(`/competition/manage/${ref}/results/${test}/${testSet}`)
    }

    recalculate(ref: CompetitionRef, test: CompetitionTestRef, testSet: CompetitionTestSetRef): Promise<void> {
        return httpService.get(`/competition/manage/${ref}/results/${test}/${testSet}/recalculate`)
    }

    getParticipationResults(ref: CompetitionRef, testRef: string, testSetRef: string, participationRef: string): Promise<ParticipationResultDetail> {
        return httpService.get(`/competition/manage/${ref}/results/${encodeURIComponent(testRef)}/${encodeURIComponent(testSetRef)}/${encodeURIComponent(participationRef)}`)
    }

    saveParticipationResults(ref: CompetitionRef, testRef: string, testSetRef: string, participationRef: string, data: ResultDataSaveRequest): Promise<PractitionerResult> {
        return httpService.post(`/competition/manage/${ref}/results/${encodeURIComponent(testRef)}/${encodeURIComponent(testSetRef)}/${encodeURIComponent(participationRef)}`, data)
    }

    exportCompetitionToExcel(competition: CompetitionRef, exportType: ExportType, withResultDetails: boolean): Promise<FileContent> {
        return httpService.download(`/competition/${competition}/results/export?exportType=${exportType}&withResultDetails=${withResultDetails}`)
    }

    importCompetitionResults(ref: CompetitionRef, resultData: UploadResultsRequest): Promise<ImportResult> {
        return httpService.upload(`/competition/${ref}/results/import`, resultData.file)
    }
}

export enum ExportType {
    GENERIC = "GENERIC",
    KNHS_SHOWJUMPING = "KNHS_SHOWJUMPING",
    KNHS_DRESSAGE = "KNHS_DRESSAGE"
}

export interface CompetitionResultsData {
    menu: ResultsMenu
}

export interface ResultsMenu {
    entries: Array<ConfigurationTestMenuEntry>
}

export interface ConfigurationTestMenuEntry {
    ref: CompetitionTestRef,
    name: string,
    entries: Array<TestMenuEntry>
}

export interface TestMenuEntry {
    ref: CompetitionTestRef
    name: string,
    testSet: RefWithName,
    hasMultipleTestSets: boolean,
    includedInCareer: boolean,
    includedInChampionship: boolean
    isFinal: boolean
}


export interface Results {
    competitionRef: string,
    testRef: string,
    testSet: RefWithName,
    name: string,
    sportTypeVariant: SportTypeVariant,
    sportCategory: SportCategory,
    career?: RefWithName,
    championship?: RefWithName,
    headers: Array<ResultDataHeader>,
    entries: Array<PractitionerResult>,
    urls: Array<UrlWithKey>,
    isFinal: boolean
}

export interface UrlWithKey {
    key: string,
    url: string
}

export interface ResultDataHeader {
    testResultId: string,
    testRef: string,
    testPartRef: string,
    order: number,
    name: string,
    resultType: SportResultDataType
}

export interface ParticipationResultDetail {
    participation: CompetitionParticipation,
    results: Array<TestResultDetail>
}

export interface TestResultDetail {
    testResultId: string,
    testPartCode: string,
    order: number,
    name: string,
    input?: SportResultInput,
    parameters: ResultHeaderParameters
}


export enum ResultHeaderParametersType {
    SHOW_JUMPING = "SHOW_JUMPING",
    DRESSAGE = "DRESSAGE",
    STYLE_RIDING = "STYLE_RIDING"
}

export interface ResultHeaderParameters {
    type: ResultHeaderParametersType
}

export interface JumpingResultHeaderParameters extends ResultHeaderParameters {
    round: number;
    speed: number;
    length: number;
    countdownTime: number;
    allowedTime: number;
    timeLimit: number;
    obstacles: Array<JumpingObstacle>;
}

export interface DressageResultHeaderParameters extends ResultHeaderParameters {
    position: DressageJuryPosition;
    jury?: PersonName;
    secretary?: PersonName;
    exercises: Array<DressageProtocolMarker>;
    collectives: Array<DressageProtocolMarker>;
    assessments: Array<DressageProtocolMarker>;
    totalMaxPoints: number,
    penaltyPoints: Array<DressagePenaltyPointSpecification>
}

export interface DressageManualResultHeaderParameters extends ResultHeaderParameters {
    position: DressageJuryPosition;
    jury?: PersonName;
    secretary?: PersonName;
    markers: Array<DressageProtocolMarker>;
    totalMaxPoints: number
}

export interface DressagePenaltyPointSpecification {
    type: DressagePenaltyPointType,
    percentage?: number,
    points?: number,
    status?: SportResultStatus,
}

export enum DressagePenaltyPointType {
    PERCENTAGE = "PERCENTAGE",
    POINTS = "POINTS",
    STATUS = "STATUS"
}

export interface DressageProtocolMarker {
    id: string;
    order: number;
    step: string;
    location: string;
    title: string;
    description?: string;
    noScore: boolean;
    defaultValue: number;
    coefficient: number;
    minValue: number;
    maxValue: number;
    stepValue: number;
    visualisationType: ScoreInputVisualisationType;
    excludeFromScore: boolean;
    withRemarks: boolean;
    keywords: Array<string>;
    penaltyPoints: Array<DressagePenaltyPointSpecification>;
}

export interface StyleRidingResultHeaderParameters extends ResultHeaderParameters {
    markers: Array<StyleRidingProtocolMarker>;
    totalMaxPoints: number
}

export interface StyleRidingProtocolMarker {
    id: string;
    order: number;
    step: string;
    location: string;
    title: string;
    description?: string;
    required: boolean;
    defaultValue: number;
    coefficient: number;
    minValue: number;
    maxValue: number;
    stepValue: number;
    visualisationType: ScoreInputVisualisationType;
    excludeFromScore: boolean;
}

export enum ScoreInputVisualisationType {
    TEXT = "TEXT",
    SLIDER = "SLIDER",
    DROPDOWN = "DROPDOWN",
    RATING = "RATING"
}

export interface CrossCountryResultHeaderParameters extends ResultHeaderParameters {
    speed: number;
    length: number;
    countdownTime: number;
    optimalTime: number;
    allowedTime: number;
    obstacles: Array<CrossCountryObstacle>;
    obstacleErrorSpecifications: Array<CrossCountryObstacleErrorSpecification>
}

export interface CrossCountryObstacleErrorSpecification extends CrossCountryObstacleErrorHolder {
    type: CrossCountryObstacleErrorType,
    noError?: boolean,
    maxCount: number,
    states?: Array<CrossCountryObstacleErrorStateSpecification>
}

export interface CrossCountryObstacleErrorStateSpecification extends CrossCountryObstacleErrorHolder {
    condition: CrossCountryObstacleErrorStateConditionSpecification
}

export interface CrossCountryObstacleErrorStateConditionSpecification {
    count: number
}

export interface CrossCountryObstacleErrorHolder {
    penalties?: number,
    status?: SportResultStatus,
}

export interface PresentationResultHeaderParameters extends ResultHeaderParameters {
    jury?: PersonName;
    secretary?: PersonName;
    markers: Array<PresentationProtocolMarker>;
    totalMaxPoints: number
}

export interface PresentationProtocolMarker {
    id: string;
    order: number;
    step: string;
    title: string;
    description?: string;
    required: boolean;
    defaultValue: number;
    coefficient: number;
    minValue: number;
    maxValue: number;
    stepValue: number;
    visualisationType: ScoreInputVisualisationType;
    excludeFromScore: boolean;
}

export interface PractitionerResult {
    ref: StartlistEntryRef,
    order: number,
    outOfCompetition: boolean,
    practitioner: CompetitionPractitioner,
    results: Array<TestResult>
}

export interface ResultDataSaveRequest {
    outOfCompetition: boolean,
    results: TestResultInput[]
}

export interface TestResultInput {
    testResultId: string
    testPartCode: string
    result: SportResultInput
}

export interface UploadResultsRequest {
    file: File
}

export type TestBindingRef = string
export type StartlistGroupRef = string
export type StartlistRef = string
export type StartlistEntryRef = string

export interface ImportResult {
    isSuccess: boolean,
    messages: Array<ErrorMessage>
}

export enum Severity {
    FATAL = "FATAL",
    ERROR = "ERROR",
    WARN = "WARN",
    INFO = "INFO",
    DEBUG = "DEBUG"
}

export interface ErrorMessage {
    message: string,
    severity: Severity
}

export const manageResultsService = new ManageResultsService();
