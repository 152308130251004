import {Account} from "@/modules/federation/services/InvoiceService";
import {MoneyWithTax} from "@/shared/domain/Finance";
import {AccountRefWithName} from "@/shared/domain/Account";
import {RefWithName} from "@/utils/Utils";

export type AvatarUrl = String;

export enum Language {
    EN = "EN",
    NL = "NL",
    /*FR = "FR",
    DE = "DE",*/
}

export enum EqifyFeature {
    EQUESTER_IMPORT = "EQUESTER_IMPORT",
    UBL_EXPORT = "UBL_EXPORT",
    EXACT_ONLINE = "EXACT_ONLINE",
    KNHS_SUBSCRIPTION_IMPORT = "KNHS_SUBSCRIPTION_IMPORT",
    KNHS_DRESSAGE_EXPORT = "KNHS_DRESSAGE_EXPORT",
    KNHS_JUMPING_EXPORT = "KNHS_JUMPING_EXPORT",
    EQUIPE_IMPORT = "EQUIPE_IMPORT",
    EQUIBASE_IMPORT = "EQUIBASE_IMPORT",
    ZANGERSHEIDE_IMPORT = "ZANGERSHEIDE_IMPORT"
}

export interface PriceProfile {
    ref?: string,
    order?: string,
    type: string,
    money?: MoneyWithTax,
    lateRegistrationMoney?: MoneyWithTax,
    noShowMoney?: MoneyWithTax
    paymentType: string,
    rules: Object,
    registrationArticleRef?: string,
    lateRegistrationArticleRef?: string,
    noShowArticleRef?: string,
    default?: boolean,
    practitionerPriceProfileRef?: string,
    splits?: Array<PriceProfileSplitRevenue>
}

export interface PriceProfileSplitRevenue {
    ref?: string,
    split: SplitValue,
    account: AccountRefWithName,
    article: RefWithName,
    type: PriceProfileSplitRevenueType
}

export interface SplitValue {
    value: number,
    type: SplitValueType
}

export enum SplitValueType {
    PERCENTAGE = "PERCENTAGE",
    ABSOLUTE = "ABSOLUTE"
}

export enum PriceProfileSplitRevenueType {
    REGISTRATION = "REGISTRATION",
    LATE_REGISTRATION = "LATE_REGISTRATION",
    NO_SHOW_REGISTRATION = "NO_SHOW_REGISTRATION"
}

export interface Contact {
    ref: string,
    name: string,
    type: ContactType
}

export enum ContactType {
    PERSON = "PERSON",
    ORGANISATION = "ORGANISATION",
    COMPETITION = "COMPETITION"
}

export interface RulesTemplate {
    ref?: string,
    rules: Object
}

export interface CompetitionTestFinanceRecipient {
    ref: string,
    recipient: Account,
    split: number
}

export enum PaymentType {
    DIRECT = "DIRECT",
    DEFERRED = "DEFERRED",
    FREE = "FREE",
    EXTERN = "EXTERN"
}

export enum Currency {
    EURO = "EUR"
    // DOLLAR = "USD"
}

export interface FederationName {
    ref: string;
    name: string;
    avatar?: string
}

export enum ScoreInputVisualisationType {
    INPUT = "INPUT",
    SLIDER = "SLIDER",
    DROPDOWN = "DROPDOWN",
    RATING = "RATING"
}