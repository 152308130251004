import {Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {organisationFinanceService} from "@/modules/organisations/services/OrganisationFinanceService";
import {Money} from "@/shared/domain/Finance";

@Module({namespaced: true, name: 'organisation-finance'})
export default class OrganisationFinanceModule extends VuexModule {
    balance: Money = {} as Money

    @MutationAction
    async loadBalance(req: LoadBalanceRequest) {
        const response = await organisationFinanceService.getBalance(req.ref)
        return {
            balance: response.amount,
        }
    }
}

interface LoadBalanceRequest {
    ref: string
}
