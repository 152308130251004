import Vue from 'vue'
import Vuex, {StoreOptions} from 'vuex'
import {RootState} from "@/store/types";
import competitionProfileModule from "@/modules/competition/store/competition-profile-module";
import championshipProfileModule from "@/modules/championship/store/championship-profile-module";
import championshipManageModule from "@/modules/championship/store/manage-championship-module";
import championshipManageLayoutModule from "@/modules/championship/store/manage-championship-layout-module";
import competitionManageModule from "@/modules/competition/store/competition-manage-module";
import competitionManageTestsModule from "@/modules/competition/store/competition-manage-tests-module";
import competitionManageTimescheduleModule from "@/modules/competition/store/competition-manage-timeschedule-module";
import competitionManageSubscriptionsModule from "@/modules/competition/store/competition-manage-subscriptions-module";
import competitionManageStartlistModule from "@/modules/competition/store/competition-manage-startlist-module";
import competitionManageResultsModule from "@/modules/competition/store/competition-manage-results-module";
import competitionManageRankingModule from "@/modules/competition/store/competition-manage-ranking-module";
import memberProfileModule from "@/modules/members/store/member-profile-module";
import horseProfileModule from "@/modules/horses/store/horse-profile-module";
import organisationProfileModule from "@/modules/organisations/store/organisation-profile-module";
import organisationMembersModule from "@/modules/organisations/store/organisation-members-module"
import organisationFinanceModule from "@/modules/organisations/store/organisation-finance-module"
import memberFinanceModule from "@/modules/members/store/member-finance-module";
import courseEventProfileModule from '@/modules/course/store/CourseEventProfileModule';
import CompetitionFinanceModule from "@/modules/competition/views/manage/finance/CompetitionFinanceModule";
import CompetitionIndividualRegistrationModule
    from "@/modules/competition/store/registration/competition-individual-registration-module";
import CompetitionHorseRegistrationModule
    from "@/modules/competition/store/registration/competition-horse-registration-module";
import CompetitionTeamRegistrationModule
    from "@/modules/competition/store/registration/competition-team-registration-module";
import FastNavigationSidebarModule from "@/modules/sidebar/store/fast-navigation-sidebar-module";
import FinanceOrderStore from "@/modules/finance/views/FinanceOrderStore";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    state: {
        version: '1.0.0',
    },
    modules: {
        'competition-profile': competitionProfileModule,
        'competition-manage': competitionManageModule,
        'competition-manage-tests': competitionManageTestsModule,
        'competition-manage-timeschedule': competitionManageTimescheduleModule,
        'competition-manage-subscriptions': competitionManageSubscriptionsModule,
        'competition-manage-startlist': competitionManageStartlistModule,
        'competition-manage-results': competitionManageResultsModule,
        'competition-manage-ranking': competitionManageRankingModule,
        'championship-profile': championshipProfileModule,
        'championship-manage': championshipManageModule,
        'championship-manage-layout': championshipManageLayoutModule,
        'member-profile': memberProfileModule,
        'member-finance': memberFinanceModule,
        'horse-profile': horseProfileModule,
        'organisation-profile': organisationProfileModule,
        'organisation-members': organisationMembersModule,
        'organisation-finance': organisationFinanceModule,
        'competition-finance': CompetitionFinanceModule,
        'courseEvent-profile': courseEventProfileModule,
        'competition-individual-registration-module': CompetitionIndividualRegistrationModule,
        'competition-horse-registration-module': CompetitionHorseRegistrationModule,
        'competition-team-registration-module': CompetitionTeamRegistrationModule,
        'fast-navigation-sidebar-module': FastNavigationSidebarModule,
        'financeOrderStore': FinanceOrderStore
    }
};

export default new Vuex.Store<RootState>(store);
