import {Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {
    emptySearchOrderRequest,
    InvoiceStatus,
    Order,
    orderService,
    OrderStatus,
    PaymentStatus,
    SearchOrderRequest
} from "@/modules/finance/services/OrderService";
import {PagedResponse} from "@/components/InfiniteScrollList.vue";

@Module({namespaced: true, name: 'financeOrderStore'})
export default class FinanceOrderStore extends VuexModule {
    items: PagedResponse<Order> = {page: 1, items: new Array<Order>()} as PagedResponse<Order>
    page: number = 1
    filter: SearchOrderRequest = emptySearchOrderRequest()

    @MutationAction
    async applyFilter(req: ApplyFilterRequest) {
        const response = await orderService.searchOrders(req.federationRef, req, req.page)
        return {
            filter: req as SearchOrderRequest,
            items: response,
            page: req.page
        }
    }
}

interface ApplyFilterRequest {
    federationRef: string
    page: number
    orderStatus?: Array<OrderStatus>
    paymentStatus?: Array<PaymentStatus>
    invoiceStatus?: Array<InvoiceStatus>
    text?: string
}

export function emptyFilter(federationRef: string) {
    return {federationRef: federationRef, page: 1} as ApplyFilterRequest
}

export function buildApplyFilterRequest(federationRef: string, filter: SearchOrderRequest, page: number) {
    return {...filter, federationRef: federationRef, page: page} as ApplyFilterRequest
}