import {FileContent, httpService} from "@/services/HttpService";
import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {Money, MoneyWithTax} from "@/shared/domain/Finance";
import {Bill, BillItem} from "@/services/FinanceService";
import {RefWithName} from "@/utils/Utils";
import {MemberRef} from "@/modules/members/services/MemberService";
import {BillItemData} from "@/components/finance/FinanceTransactionCallback";
import {PaymentStatus} from "@/modules/finance/services/OrderService";

class CompetitionFinanceService {
    searchFinanceItems(ref: CompetitionRef, request: SearchFinanceItemsRequest): Promise<Array<CompetitionFinanceItem>> {
        return httpService.post(`/competition/manage/${ref}/finance/items/search`, request)
    }

    exportFinanceItems(ref: CompetitionRef, request: SearchFinanceItemsRequest): Promise<FileContent> {
        return httpService.download(`/competition/manage/${ref}/finance/items/export`, request)
    }

    getBills(ref: CompetitionRef): Promise<Array<Bill>> {
        return httpService.get(`/competition/manage/${ref}/finance/bill`)
    }

    getBillItems(ref: CompetitionRef, billRef: string): Promise<CompetitionBillItems> {
        return httpService.get(`/competition/manage/${ref}/finance/bill/${billRef}/item`)
    }

    updateBillItem(ref: CompetitionRef, billRef: string, billItemRef: string, body: BillItemData) {
        return httpService.put(`/competition/manage/${ref}/finance/bill/${billRef}/item/${billItemRef}`, body)
    }

    addBillItem(ref: MemberRef, billRef: string): Promise<void> {
        return httpService.post(`/competition/manage/${ref}/finance/bill/${billRef}/item`, {})
    }

    deleteBillItem(ref: MemberRef, billRef: string, billItemRef: string): Promise<void> {
        return httpService.delete(`/competition/manage/${ref}/finance/bill/${billRef}/item/${billItemRef}`)
    }

    moveBillItem(ref: MemberRef, billRef: string, targetBillRef: string, billItemRef: string): Promise<void> {
        return httpService.put(`/competition/manage/${ref}/finance/bill/${billRef}/item/${billItemRef}/target/${targetBillRef}`, {})
    }

    getBalance(ref: CompetitionRef): Promise<CompetitionBalance> {
        return httpService.get(`/competition/manage/${ref}/finance/bill/balance`)
    }

    splitPayments(
        ref: CompetitionRef
    ): Promise<void> {
        return httpService.post(`/competition/manage/${ref}/finance/split-payments`, {})
    }

    getSplitInfo(
        ref: CompetitionRef
    ): Promise<CompetitionSplitInfo> {
        return httpService.get(`/competition/manage/${ref}/finance/splits`)
    }
}

export interface CompetitionFinanceItem {
    ref: string,
    to: RefWithName,
    toType: string,
    invoiceOrBillTo: RefWithName,
    invoiceOrBillToType: string,
    amount: Money,
    datetime: string,
    description: string,
    status: string,
    paymentStatus: string,
    type: CompetitionFinanceItemType,
    incoming: boolean
}

export enum CompetitionFinanceItemType {
    ORDER = "ORDER",
    BILL = "BILL"
}

export interface CompetitionBalance {
    income: Balance,
    unpaid: Balance
}

export interface Balance {
    total: Money,
    bill: Money,
    order: Money,
}

export interface CompetitionBillItems {
    bill: Bill,
    items: Array<BillItem>,
    articles: Array<RefWithName>
}

export interface CompetitionSplitInfo {
    competitionTotalAmount: MoneyWithTax,
    recipientSplits: Array<RecipientSplit>
}

export interface RecipientSplit {
    recipient: RefWithName,
    totalAmount: MoneyWithTax,
    splits: Array<TestSplit>
}

export interface TestSplit {
    test: RefWithName,
    amount: MoneyWithTax
}

export interface SearchFinanceItemsRequest {
    paymentStatus?: Array<PaymentStatus>
    processingStatus?: Array<FinanceProcessingStatus>
    text?: string
}

export enum FinanceProcessingStatus {
    UNPROCESSED = "UNPROCESSED",
    PROCESSED = "PROCESSED",
    ERROR = "ERROR",
    CANCELLED = "CANCELLED"
}

export const competitionFinanceService = new CompetitionFinanceService()