import {FederationRef} from "@/modules/federation/services/MyFederationService";
import {httpService} from "@/services/HttpService";
import {PagedResponse} from "@/components/InfiniteScrollList.vue";
import {Money, MoneyWithTax} from "@/shared/domain/Finance";
import {Account} from "@/modules/federation/services/InvoiceService";
import {DateUtils} from "@/utils/DateUtils";
import {AccountRef} from "@/shared/domain/Account";

class OrderService {
    getOrders(federationRef: FederationRef, statuses: Array<OrderStatus>, page: number): Promise<PagedResponse<Order>> {
        return httpService.get(`/finance/${federationRef}/orders?statuses=${statuses.join(',')}&page=${page}`)
    }

    searchOrders(federationRef: FederationRef, request: SearchOrderRequest, page: number): Promise<PagedResponse<Order>> {
        return httpService.post(`/finance/${federationRef}/orders/search?page=${page}`, request)
    }

    updateOrderToAccount(federationRef: FederationRef, orderRef: OrderRef, accountRef: AccountRef): Promise<void> {
        return httpService.put(`/finance/${federationRef}/orders/${orderRef}/account/to?accountRef=${accountRef}`, {})
    }

    updateOrderInvoiceToAccount(federationRef: FederationRef, orderRef: OrderRef, accountRef: AccountRef): Promise<void> {
        return httpService.put(`/finance/${federationRef}/orders/${orderRef}/account/invoice-to?accountRef=${accountRef}`, {})
    }

    getOpenOrders(federationRef: FederationRef): Promise<Array<Order>> {
        return httpService.get(`/finance/${federationRef}/orders/open`)
    }

    getOrderDetail(federationRef: FederationRef, orderRef: OrderRef): Promise<OrderDetail> {
        return httpService.get(`/finance/${federationRef}/orders/${orderRef}`)
    }

    createInvoiceFromOrder(federationRef: FederationRef, orderRef: OrderRef): Promise<void> {
        return httpService.post(`/finance/${federationRef}/orders/${orderRef}/create-invoice`, {})
    }

    deleteOrder(federationRef: FederationRef, orderRef: OrderRef): Promise<OrderDetail> {
        return httpService.delete(`/finance/${federationRef}/orders/${orderRef}`)
    }

    processOrder(federationRef: FederationRef, orderRef: OrderRef): Promise<Order> {
        return httpService.post(`/finance/${federationRef}/orders/${orderRef}/process`, {})
    }

    deleteOrderItem(federationRef: FederationRef, orderRef: OrderRef, orderItemRef: OrderItemRef): Promise<OrderDetail> {
        return httpService.delete(`/finance/${federationRef}/orders/${orderRef}/item/${orderItemRef}`)
    }

    createEmptyOrder(federationRef: FederationRef, request: CreateEmptyOrderRequest): Promise<OrderRef> {
        return httpService.post(`/finance/${federationRef}/orders/create-empty`, request)
    }

    createFromBillOrder(federationRef: FederationRef, request: CreateFromBillRequest): Promise<OrderRef> {
        return httpService.post(`/finance/${federationRef}/orders/create-from-bill`, request)
    }

    createFromFilterOrder(federationRef: FederationRef, request: CreateFromFilterRequest): Promise<OrderRef> {
        return httpService.post(`/finance/${federationRef}/orders/create-from-filter`, {
            description: request.description,
            fromDate: request.fromDate ? DateUtils.toDateString(request.fromDate) : undefined,
            toDate: request.toDate ? DateUtils.toDateString(request.toDate) : undefined,
            organisationRef: request.organisationRef,
            articleRefs: request.articleRefs
        })
    }
}

export interface SearchOrderRequest {
    orderStatus?: Array<OrderStatus>
    paymentStatus?: Array<PaymentStatus>
    invoiceStatus?: Array<InvoiceStatus>
    text?: string
}

export function emptySearchOrderRequest() {
    return {} as SearchOrderRequest
}

export interface CreateEmptyOrderRequest {
    to?: Account,
    invoiceTo?: Account,
    costCenter?: Account,
}

export interface CreateFromBillRequest {
    billRef: string
}

export interface CreateFromFilterRequest {
    description?: string
    fromDate?: Date
    toDate: Date
    organisationRef?: string
    articleRefs?: Array<string>
}

export type OrderRef = string
export type OrderItemRef = string

export interface Order {
    ref: OrderRef,
    orderId: string,
    description?: string,
    invoiceTo: Account,
    totalAmount: Money,
    status: OrderStatus,
    paymentStatus: PaymentStatus,
    invoiceStatus: InvoiceStatus,
    date: string,
    invoice?: InvoiceLink,
}

export enum OrderStatus {
    NEW = "NEW",
    PROCESSED = "PROCESSED",
    PROCESSED_ERROR = "PROCESSED_ERROR",
    CANCELLED = "CANCELLED"
}

export enum PaymentStatus {
    INCOMPLETE = "INCOMPLETE",
    COMPLETE = "COMPLETE",
    PARTIAL = "PARTIAL",
    EXCEEDED = "EXCEEDED",
    ERROR = "ERROR"
}

export enum InvoiceStatus {
    NOT_INVOICED = "NOT_INVOICED",
    INVOICED = "INVOICED"
}

export interface OrderDetail extends Order {
    to: Account
    items: Array<OrderItem>,
    acceptablePaymentMethods: Array<PaymentMethod>,
    payments: Array<OrderPayment>,
    canDelete: boolean,
}

export interface PaymentMethod {
    ref: string,
    name: string,
    type: PaymentMethodType
}

export enum PaymentMethodType {
    CASH = "CASH",
    STRIPE = "STRIPE",
    EXACT_ONLINE = "EXACT_ONLINE"
}

export interface OrderPayment {
    paymentMethod: string,
    money: Money,
    status: OrderPaymentStatus,
    details?: any,
    manual: boolean,
    paymentDate: string,
    canDelete: boolean,
}

export enum OrderPaymentStatus {
    SUCCEEDED = "SUCCEEDED",
    FAILED = "FAILED"
}

export interface InvoiceLink {
    type: InvoiceLinkType,
    invoiceId: string
}

export enum InvoiceLinkType {
    EQIFY = "EQIFY",
    EXACT_ONLINE = "EXACT_ONLINE"
}

export interface ExactOnlineInvoiceLink extends InvoiceLink {
    documentUrl?: string
}

export interface OrderItem {
    ref: OrderItemRef,
    position: number,
    description?: string,
    articleName: string,
    articleType: string,
    amount: MoneyWithTax
}

export const orderService = new OrderService();
