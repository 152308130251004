import Vue from 'vue'
import App from './App.vue'
import BootstrapVue, {IconsPlugin} from "bootstrap-vue";
import VueLogger from 'vuejs-logger';
import i18n from './i18n'
import router from './router'
import store from './store'
import './axios'
import "./directives";
import "./filters";
import './components'
import "./vue-form-generator";
import "./tenant";
import {EqifyHttpPostPlugin} from "./httppost";
import wait from "./vue-wait";
import {VueRouter} from "vue-router/types/router";
import {Loader, LoaderPlugin} from "@/utils/Loader";
import VueKeyCloak from "./keycloak/plugin";
import {ConfigProvider} from "@/ConfigProvider";
import VueSSE from 'vue-sse';

Vue.config.productionTip = false;

declare module 'vue/types/vue' {
    interface VueConstructor {
        router: VueRouter, // needed for vue-auth
        $log: {
            debug: (message: string) => void,
            info: (message: string) => void,
            warn: (message: string) => void,
            error: (message: string) => void
        },
        $t: (message: string) => string,
        $loader: Loader
    }

    interface Vue {
        $loader: Loader
    }
}

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
// Vue.use(VueAnalytics, {
//     id: ConfigProvider.googleAnalyticsId,
//     router
// })

const isProduction = process.env.NODE_ENV === 'production';

Vue.config.errorHandler = function (err: Error, vm: Vue, info: string) {
    // console.log(err);
    vm.$toastr.e(info, "Whoops, something went wrong")
    vm.$log.error("Whoops, something went wrong", err)
}

// @ts-ignore
Vue.use(VueLogger, {
    isEnabled: true,
    logLevel: isProduction ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
});
Vue.use(LoaderPlugin, {wait: wait})
Vue.use(EqifyHttpPostPlugin);
Vue.use(VueKeyCloak, {
    config: {
        url: ConfigProvider.ssoUrl,
        realm: ConfigProvider.ssoRealm,
        clientId: ConfigProvider.ssoClientId,
    },
    init: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
    },
    onInitSuccess: (authenticated, keycloak, vueKeycloak) => {
        if (vueKeycloak && vueKeycloak.userProfile && vueKeycloak.userProfile.defaultLanguage) {
            i18n.locale = vueKeycloak.userProfile.defaultLanguage.toLowerCase()
        }
    },
    onAuthRefreshError: async () => {
        await Vue.router.replace("/")
    },
    onReady: kc => {
    }
})

Vue.use(VueSSE, {
    format: 'json',
    withCredentials: true,
});

new Vue({
    router,
    store,
    i18n,
    wait,
    render: h => {
        return h(App)
    }
}).$mount('#app')




