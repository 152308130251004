import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {SportCategoryRef} from "@/modules/federation/services/SportCategoryService";
import {httpService} from "@/services/HttpService";
import {SportTypeVariant, SportTypeVariantRef} from "@/modules/federation/services/SportTypeVariantService";
import {RefWithName} from "@/utils/Utils";
import {CareerRef} from "@/modules/federation/services/CareerService";
import {SportType} from "@/modules/federation/services/SportTypeService";
import {ChampionshipRef} from "@/modules/championship/services/PublicChampionshipService";
import {PriceProfile} from "@/shared/domain/General";
import {PractitionerType} from "@/shared/domain/Practitioner";

class CompetitionTestsService {

    getData(ref: CompetitionRef): Promise<CompetitionTestData> {
        return httpService.get(`/competition/manage/${ref}/tests/metadata`)
    }

    createForCareer(ref: CompetitionRef, careerRef: string): Promise<Array<CompetitionTest>> {
        return httpService.post(`/competition/manage/${ref}/tests/create-for-career/${careerRef}`, {})
    }

    createForSportCategory(ref: CompetitionRef, sportCategoryRef: string): Promise<CompetitionTest> {
        return httpService.post(`/competition/manage/${ref}/tests/create-for-sportcategory/${sportCategoryRef}`, {})
    }

    createForSportTypeVariant(ref: CompetitionRef, sportTypeVariantRef: string): Promise<CompetitionTest> {
        return httpService.post(`/competition/manage/${ref}/tests/create-for-sporttypevariant/${sportTypeVariantRef}`, {})
    }

    changeTestBySportTypeVariant(ref: CompetitionRef, testRef: CompetitionTestRef, sportTypeVariantRef: string): Promise<CompetitionTest> {
        return httpService.post(`/competition/manage/${ref}/tests/${testRef}/change/sporttypevariant/${sportTypeVariantRef}`, {})
    }

    saveTest(ref: CompetitionRef, request: SaveTestRequest): Promise<CompetitionTest> {
        return httpService.post(`/competition/manage/${ref}/tests`, request)
    }

    deleteCompetitionTest(ref: CompetitionRef, testRef: CompetitionTestRef): Promise<CompetitionTest> {
        return httpService.delete(`/competition/manage/${ref}/tests/${testRef}`)
    }

    cancelCompetitionTest(ref: CompetitionRef, testRef: CompetitionTestRef): Promise<void> {
        return httpService.put(`/competition/manage/${ref}/tests/${testRef}/cancel`, {})
    }

    undoCancelCompetitionTest(ref: CompetitionRef, testRef: CompetitionTestRef): Promise<void> {
        return httpService.put(`/competition/manage/${ref}/tests/${testRef}/undo-cancel`, {})
    }

    getTestDetail(ref: CompetitionRef, testRef: CompetitionTestRef): Promise<CompetitionTestDetail> {
        return httpService.get(`/competition/manage/${ref}/tests/${testRef}`)
    }

    segmentTest(ref: CompetitionRef, testRef: CompetitionTestRef, request: CompetitionTestSegmentRequest): Promise<CompetitionTest> {
        return httpService.post(`/competition/manage/${ref}/tests/${testRef}/segment`, request)
    }
}

export type CompetitionTestRef = string;
export type CompetitionTestSetRef = string;

export interface CompetitionTest {
    ref?: CompetitionTestRef,
    name: string,
    testSets: Array<RefWithName>,
    configuration: RefWithName,
    sportTypeVariant: SportTypeVariant,
    sportCategory?: SportCategory
    career: RefWithName
    championship: RefWithName,
    protocols: Array<RefWithName>,
    externalId: string,
    valid: boolean,
    cancelled: boolean
}

export interface CompetitionTestConfigurationDescriptor {
    ref: string,
    name: string,
    code: string,
    sportType: SportType
}

export interface CompetitionTestDetail extends CompetitionTest {
    parameters: CompetitionTestParameters,
    configurationParameters: TestConfigurationParameters
    practitionerGroup: PractitionerGroup
}

export function isIndividual(sportTypeVariant?: SportTypeVariant): boolean {
    return (sportTypeVariant && sportTypeVariant.kind == PractitionerType.INDIVIDUAL) || false;
}

export function isHorseOnly(sportTypeVariant?: SportTypeVariant): boolean {
    return (sportTypeVariant && sportTypeVariant.kind == PractitionerType.HORSE) || false;
}

export function isJumping(sportTypeVariant?: SportTypeVariant): boolean {
    return (sportTypeVariant && sportTypeVariant.sportType == SportType.SHOW_JUMPING) || false;
}

export function isDressage(sportTypeVariant?: SportTypeVariant): boolean {
    return (sportTypeVariant && sportTypeVariant.sportType == SportType.DRESSAGE) || false;
}

export interface CompetitionTestRound {
    name: string;
    round: number
}

export interface CompetitionTestParameters {
    careerRef?: CareerRef;
    careerPointDistributionCategoryRef?: string;
    appliesForChampionship: boolean;
    championshipRef?: ChampionshipRef;
    defaultOutOfCompetition?: boolean;
    maxSubscriptions?: number;
    maxStartlistEntries?: number;
    maxParticipationsPerRider?: number;
    maxParticipationsPerHorse?: number;
    maxParticipationsPerCombination?: number;
    allowRegistrationOfExistingCombinations?: boolean,
    allowRegistrationOfUnknownCombinations?: boolean,
    timeassignmentAllowSwitchStartlist?: boolean;
    priceProfiles: Array<PriceProfile>;
    financeRecipients: Array<CompetitionTestFinanceRecipient>
    averageRunDuration?: number;
    draggingBreakFrequency?: number;
    inheritRules: boolean;
    inheritPriceProfiles: boolean;
    inheritRegistrationSettings: boolean;
    rules?: string;
    hideFromRegistration: boolean;
}

export interface CompetitionTestFinanceRecipient {
    ref?: string,
    associationRef?: string,
    recipient: RefWithName,
    split: number
}

export interface TestConfigurationParameters {
    type: SportType
}

export interface GenericTestConfigurationParameters extends TestConfigurationParameters {
}

export interface AuctionTestConfigurationParameters extends TestConfigurationParameters {
    currency?: string,
    platform?: AuctionPlatformConfiguration,
}

export interface AuctionPlatformConfiguration {
    type: AuctionPlatformType
}

export interface PwebAuctionPlatformConfiguration extends AuctionPlatformConfiguration {
    url?: string,
    collectionId?: string,
    pusherCluster?: string,
    pusherToken?: string,
}

export interface ZangersheideAuctionPlatformConfiguration extends AuctionPlatformConfiguration {
    environment?: string,
    collectionId?: string
}

export enum AuctionPlatformType {
    SIMULATED = "SIMULATED",
    PWEB = "PWEB",
    ZANGERSHEIDE = "ZANGERSHEIDE"
}

export interface ShowJumpingTestConfigurationParameters extends TestConfigurationParameters {
    protocolRef?: string,
    rulesRef?: string,
    withStyle: boolean,
    styleProtocolRef?: string,
    styleRulesRef?: string,
    includeJumpingRanking?: boolean,
    styleSportCategoryRef?: string,
}

export interface DressageTestConfigurationParameters extends TestConfigurationParameters {
    rounds: Array<DressageTestConfigurationRound>
}

export interface DressageTestConfigurationRound {
    order: number
    protocolRef?: string,
    rulesRef?: string
}

export interface EventingTestConfigurationParameters extends TestConfigurationParameters {
    withJumping: boolean,
    withDressage: boolean,
    withCrossCountry: boolean,
    jumpingProtocolRef?: string,
    dressageProtocolRef?: string,
    crossCountryProtocolRef?: string,
    rulesRef?: string
}

export interface PresentationTestConfigurationParameters extends TestConfigurationParameters {
    rounds: Array<PresentationTestConfigurationRound>
}

export interface PresentationTestConfigurationRound {
    order: number
    protocolRef?: string
    name?: string
    weight?: number,
    final?: boolean
}

export interface CreateForCareerRequest {
    competitionRef: CompetitionRef,
    careerRef: string
}

export interface CreateForSportCategoryRequest {
    competitionRef: CompetitionRef,
    sportCategoryRef: string
}

export interface CreateForSportTypeVariantRequest {
    competitionRef: CompetitionRef,
    sportTypeVariantRef: string
}

export interface SaveTestRequest {
    competitionRef: CompetitionRef,
    ref?: string,
    name: string,
    sportTypeVariantRef: SportTypeVariantRef,
    sportCategoryRef?: SportCategoryRef,
    externalId?: string,
    parameters: CompetitionTestParameters,
    configurationRef?: string,
    configurationParameters: TestConfigurationParameters
}

export interface CompetitionTestData {
    sportTypeVariants: Array<SportTypeVariant>
    categories: Array<SportCategory>
    configurations: Array<CompetitionTestConfigurationDescriptor>,
    tests: Array<CompetitionTest>,
    careers: Array<RefWithName>
    championships: Array<RefWithName>
    practitionerGroups: Array<PractitionerGroup>
}

export interface Career {
    ref: CareerRef,
    name: string,
    sportTypeVariants: Array<SportTypeVariant>,
    categories: Array<SportCategory>,
    pointDistributionCategories: Array<RefWithName>
}

export interface CompetitionTestTemplate {
    ref: string,
    name: string,
    sportTypeVariants: Array<SportTypeVariant>
}

export interface SportCategory {
    ref: SportCategoryRef,
    name: string,
    sportTypeVariant: SportTypeVariant,
    protocol?: RefWithName
}


export type PractitionerGroupRef = string

export interface PractitionerGroup {
    ref: PractitionerGroupRef,
    name: string,
    practitionerType: PractitionerType,
    default: boolean
}

export enum CompetitionTestSegmentType {
    MANUAL = "MANUAL",
    AVG_CAPACITY = "AVG_CAPACITY"
}

export interface CompetitionTestSegmentRequest {
    parameters: CompetitionTestSegmentParameters
}

export interface CompetitionTestSegmentParameters {
    type: CompetitionTestSegmentType
}

export interface ManualCompetitionTestSegmentParameters extends CompetitionTestSegmentParameters {
    count: number
}

export interface AverageCapacityCompetitionTestSegmentParameters extends CompetitionTestSegmentParameters {
    capacity: number,
    padWithOpenPositions: boolean
}

export const competitionTestsService = new CompetitionTestsService();
