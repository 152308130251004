import axios, {AxiosRequestConfig} from 'axios';
import {ConfigProvider} from "@/ConfigProvider";
import Vue from 'vue'

export interface HttpService {

    get<T>(path: string, options: HttpServiceRequestOptions | undefined): Promise<T>;

    post<B, T>(path: string, body: B, options: HttpServiceRequestOptions | undefined): Promise<T>;

    put<B, T>(path: string, body: B, options: HttpServiceRequestOptions | undefined): Promise<T>;

    delete<T>(path: string, options: HttpServiceRequestOptions | undefined): Promise<T>;

    upload<T>(path: string, file: File, body: any | undefined, options: HttpServiceRequestOptions | undefined): Promise<T>;

    uploadBody<T>(path: string, file: File, body: any | undefined, options: HttpServiceRequestOptions | undefined): Promise<T>;

    download(path: string, body: any | undefined, options: HttpServiceRequestOptions | undefined): Promise<FileContent>;

    updateAuthorizationToken(token: string | undefined): void;
}

export interface FileContent {
    data: Blob,
    name: string
}

class DefaultHttpService implements HttpService {
    private readonly baseUrl = ConfigProvider.serviceBaseUrl;
    private token: string | undefined;

    get<T>(path: string, options: HttpServiceRequestOptions | undefined = undefined): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            Vue.$log.debug(`HttpService.get(${path})`);
            axios.get(`${options && options.isRaw ? '' : this.baseUrl}${path}`, this.options(options))
                .then(response => {
                    Vue.$log.debug(`HttpService.get(${path}) | SUCCESS | ${response.data}`);
                    resolve(response.data)
                })
                .catch(error => {
                    const errorMessage = error.response.headers["error-message"];
                    const causes: string[] = [];
                    if (errorMessage) {
                        const found = errorMessage.match(/errorCode=([0-9-]+)/gi);
                        if (found && found.length > 0) {
                            found.forEach((code: string) => causes.push(`errorcodes.${code.replace("errorCode=", "")}`))
                        }
                    }
                    error.functionalCauses = causes
                    Vue.$log.debug(`HttpService.get(${path}) | ERROR | ${error}`);
                    this.handleError(error, options);
                    reject(error);
                })
        });
    }

    post<B, T>(path: string, body: B, options: HttpServiceRequestOptions | undefined = undefined): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            Vue.$log.debug(`HttpService.post(${path}, ${JSON.stringify(body)})`);
            axios.post((options?.isRaw || false) ? path : `${this.baseUrl}${path}`, body, this.options(options))
                .then(response => {
                    Vue.$log.debug(`HttpService.post(${path}) | SUCCESS | ${response.data}`);
                    resolve(response.data)
                })
                .catch(error => {
                    const errorMessage = error.response.headers["error-message"];
                    const causes: string[] = [];
                    if (errorMessage) {
                        const found = errorMessage.match(/errorCode=([0-9-]+)/gi);
                        if (found && found.length > 0) {
                            found.forEach((code: string) => causes.push(`errorcodes.${code.replace("errorCode=", "")}`))
                        }
                    }
                    error.functionalCauses = causes
                    Vue.$log.debug(`HttpService.post(${path}) | ERROR | ${error}`);
                    this.handleError(error, options);
                    reject(error);
                })
        });
    }

    put<B, T>(path: string, body: B, options: HttpServiceRequestOptions | undefined = undefined): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            Vue.$log.debug(`HttpService.put(${path})`);
            axios.put(`${this.baseUrl}${path}`, body, this.options(options))
                .then(response => {
                    Vue.$log.debug(`HttpService.put(${path}) | SUCCESS | ${response.data}`);
                    resolve(response.data)
                })
                .catch(error => {
                    const errorMessage = error.response.headers["error-message"];
                    const causes: string[] = [];
                    if (errorMessage) {
                        const found = errorMessage.match(/errorCode=([0-9-]+)/gi);
                        if (found && found.length > 0) {
                            found.forEach((code: string) => causes.push(`errorcodes.${code.replace("errorCode=", "")}`))
                        }
                    }
                    error.functionalCauses = causes
                    Vue.$log.debug(`HttpService.put(${path}) | ERROR | ${error}`);
                    this.handleError(error, options);
                    reject(error);
                })
        });
    }

    delete<T>(path: string, options: HttpServiceRequestOptions | undefined = undefined): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            Vue.$log.debug(`HttpService.delete(${path})`);
            axios.delete(`${this.baseUrl}${path}`, this.options(options))
                .then(response => {
                    Vue.$log.debug(`HttpService.delete(${path}) | SUCCESS | ${response.data}`);
                    resolve(response.data)
                })
                .catch(error => {
                    const errorMessage = error.response.headers["error-message"];
                    const causes: string[] = [];
                    if (errorMessage) {
                        const found = errorMessage.match(/errorCode=([0-9-]+)/gi);
                        if (found && found.length > 0) {
                            found.forEach((code: string) => causes.push(`errorcodes.${code.replace("errorCode=", "")}`))
                        }
                    }
                    error.functionalCauses = causes
                    Vue.$log.debug(`HttpService.delete(${path}) | ERROR | ${error}`);
                    this.handleError(error, options);
                    reject(error);
                })
        });
    }

    upload<T>(path: string, file: File, body: any | undefined = undefined, options: HttpServiceRequestOptions | undefined = undefined): Promise<T> {
        return this.uploadBody(path, body ? {file: file, ...body} : {file: file}, options)
    }

    uploadBody<T>(path: string, body: any | undefined, options: HttpServiceRequestOptions | undefined = undefined): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            Vue.$log.debug(`HttpService.upload(${path})`);
            const formData = new FormData();
            if (body) {
                for (const key of Object.keys(body)) {
                    formData.append(key, body[key] as string)
                }
            }

            const o = this.options(options);
            o.headers["Content-Type"] = "multipart/form-data";
            axios.post(`${this.baseUrl}${path}`, formData, o)
                .then(response => {
                    Vue.$log.debug(`HttpService.upload(${path}) | SUCCESS | ${response.data}`);
                    resolve(response.data)
                })
                .catch(error => {
                    Vue.$log.debug(`HttpService.upload(${path}) | ERROR | ${error}`);
                    this.handleError(error, options);
                    reject(error);
                })
        });
    }

    download(path: string, body: any | undefined = undefined, options: HttpServiceRequestOptions | undefined = undefined): Promise<FileContent> {
        return new Promise<FileContent>((resolve, reject) => {
            Vue.$log.debug(`HttpService.download(${path})`);
            const o = this.options(options)
            o.responseType = 'blob'

            let call
            if (body) {
                call = axios.post(`${this.baseUrl}${path}`, body, o)
            } else {
                call = axios.get(`${this.baseUrl}${path}`, o)
            }
            call
                .then(response => {
                    const fileName = (response.headers["content-disposition"] || "").replace("attachment; filename=", "").replaceAll("\"", "")
                    Vue.$log.debug(`HttpService.download(${path}) | SUCCESS | ${response.data} |  ${fileName}`);
                    resolve({data: response.data, name: fileName})
                })
                .catch(error => {
                    Vue.$log.debug(`HttpService.download(${path}) | ERROR | ${error}`);
                    this.handleError(error, options);
                    reject(error);
                })
        });
    }

    updateAuthorizationToken(token: string | undefined): void {
        if (token) {
            Vue.$log.debug(`HttpService.updateAuthorizationToken(${token}) `);
            this.token = token;
        } else {
            Vue.$log.debug(`HttpService.updateAuthorizationToken() - INVALID`);
            delete this.token;
        }
    }

    private handleError(error: string, options: HttpServiceRequestOptions | undefined) {
        if (!this.hideToast(options)) {
            // this.$toastr.e("Failed to perform! " + error);
        }
    }

    private options(options: HttpServiceRequestOptions | undefined): AxiosRequestConfig {
        const o = Object.assign({token: this.token}, options);
        o.headers = options ? options.headers || {} : {};
        if (o.token) {
            o.headers["Authorization"] = `Bearer ${o.token}`
        }
        o.headers["eqify-domain"] = window.location.hostname
        return o
    }

    private hideToast(options: HttpServiceRequestOptions | undefined): boolean {
        if (!options) {
            return false;
        } else {
            return options.hideToast || false
        }
    }

}

export class HttpServiceRequestOptions {
    token?: string;
    hideToast?: boolean = false;
    isRaw?: boolean = false;
    headers?: any = {};
    params?: any = {};
}

export const httpService = new DefaultHttpService();
