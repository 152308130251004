import {Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {
    CompetitionFinanceItem,
    competitionFinanceService,
    FinanceProcessingStatus,
    SearchFinanceItemsRequest
} from "@/modules/competition/service/CompetitionFinanceService";
import {PaymentStatus} from "@/modules/finance/services/OrderService";

@Module({namespaced: true, name: 'competition-finance'})
export default class CompetitionFinanceModule extends VuexModule {
    items: Array<CompetitionFinanceItem> = []
    filter: SearchFinanceItemsRequest = emptyFilter("")

    @MutationAction
    async applyFilter(req: ApplyFilterRequest) {
        const response = await competitionFinanceService.searchFinanceItems(req.competitionRef, req)
        return {
            filter: req as SearchFinanceItemsRequest,
            items: response
        }
    }
}

interface ApplyFilterRequest {
    competitionRef: string
    paymentStatus?: Array<PaymentStatus>
    processingStatus?: Array<FinanceProcessingStatus>
    text?: string
}

export function emptyFilter(competitionRef: string) {
    return {competitionRef: competitionRef} as ApplyFilterRequest
}